// 引入axios封装
import axios from 'axios'
import { ElMessage, ElMessageBox } from 'element-plus'
import router from '@/router';
import { COMMON_URL } from '../public/IP'

const BASE_URL = COMMON_URL+'/bls/design/styleImg/'

function queryImg(styleNo: any, styleImg: HTMLImageElement) {
    const queryUrl = BASE_URL + "queryImg";
    console.log(queryUrl)
    axios({
        method: "post",
        url: queryUrl,
        headers: {
            'Content-type': 'application/x-www-form-urlencoded',
            'Authorization': window.sessionStorage.getItem('token')
        },
        data: styleNo
    }).then(respone => {
        styleImg.src = respone.data
    }).catch(reason => {
        console.log(reason);
    })
}

function queryImg2(styleNo: any, styleImg: HTMLImageElement) {
    const queryUrl = BASE_URL + "queryImg2";
    console.log(queryUrl)
    axios({
        method: "post",
        url: queryUrl,
        headers: {
            'Content-type': 'application/x-www-form-urlencoded',
            'Authorization': window.sessionStorage.getItem('token')
        },
        data: styleNo
    }).then(respone => {
        styleImg.src = respone.data
    }).catch(reason => {
        console.log(reason);
    })
}

function queryCloth(param:any,tableData:any) {
    const queryUrl = BASE_URL + "queryCloth";
    axios({
        method: "post",
        url: queryUrl,
        data: param
    }).then(respone => {
        if (respone.data.errorCode == "111111") {
            ElMessageBox.alert(respone.data.errorMsg, '提示信息')
            return;
        }
     
        tableData.value = respone.data.result
    }).catch(reason => {
        console.log(reason);
    })
}

function queryTech(param:any,tableData:any) {
    const queryUrl = BASE_URL + "queryTech";
    axios({
        method: "post",
        url: queryUrl,
        data: param
    }).then(respone => {
        if (respone.data.errorCode == "111111") {
            ElMessageBox.alert(respone.data.errorMsg, '提示信息')
            return;
        }
     
        tableData.value = respone.data.result
    }).catch(reason => {
        console.log(reason);
    })
}


export {queryImg,queryImg2,queryCloth,queryTech}