// 引入axios封装
import axios from 'axios'
import Qs from 'qs'
import { reactive, ref, toRaw, toRefs } from 'vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import { COMMON_URL } from '../../public/IP'

const BASE_URL = COMMON_URL + '/bls/prod/prodPlan/'

function syncSalesData(planDate:any,synching:any) {
    const url = BASE_URL + "syncSalesData";
    console.log(url);
    axios({
        method: "post",
        url: url,
        headers: {
            'Content-type': 'application/x-www-form-urlencoded',
            'Authorization': window.sessionStorage.getItem('token')
        },
        data:planDate
    }).then(respone => {
        ElMessageBox.alert(respone.data, '提示信息');
        synching.value = false;
    }).catch(reason => {
        ElMessageBox.alert(reason.data, '提示信息')
    })
}

function calculateProdPlan(data: any,calculating:any) {
    const addUrl = BASE_URL + "calculateProdPlan";
    console.log(addUrl);
    axios({
        method: "post",
        url: addUrl,
        headers: {
            'Content-type': 'application/x-www-form-urlencoded',
            'Authorization': window.sessionStorage.getItem('token')
        },
        data
    }).then(respone => {
        ElMessageBox.alert(respone.data, '提示信息')
        calculating.value = false;
    }).catch(reason => {
        ElMessageBox.alert(reason.data, '提示信息')
    })
}

function query(param: any, result: any) {
    const queryUrl = BASE_URL + "query";
    console.log(queryUrl)
    axios({
        method: "post",
        url: queryUrl,
        headers: {
            'Content-type': 'application/x-www-form-urlencoded',
            'Authorization': window.sessionStorage.getItem('token')
        },
        data: param
    }).then(respone => {
        if (respone.data.errorCode == "111111") {
            ElMessageBox.alert(respone.data.errorMsg, '提示信息')
            return;
        }
        result.value = respone.data.result;
    }).catch(reason => {
        console.log(reason);
    })
}

function queryDeliveryBillSyncTime(result: any) {
    const queryUrl = BASE_URL + "queryDeliveryBillSyncTime";
    console.log(queryUrl)
    axios({
        method: "post",
        url: queryUrl,
        headers: {
            'Content-type': 'application/x-www-form-urlencoded',
            'Authorization': window.sessionStorage.getItem('token')
        }
    }).then(respone => {
        if (respone.data.errorCode == "111111") {
            ElMessageBox.alert(respone.data.errorMsg, '提示信息')
            return;
        }
        result.value = respone.data
    }).catch(reason => {
        console.log(reason);
    })
}


export { syncSalesData,query,calculateProdPlan,queryDeliveryBillSyncTime}