// 引入axios封装
import axios from 'axios'
import { ElMessage, ElMessageBox } from 'element-plus'
import router from '@/router';
import { COMMON_URL } from '../public/IP'

const BASE_URL = COMMON_URL+'/system/store/'

function save(data: any) {
    const saveUrl = BASE_URL + "save";
    console.log(saveUrl);
    axios({
        method: "post",
        url: saveUrl,
        headers: {
            'Content-type': 'application/x-www-form-urlencoded'
        },
        data
    }).then(respone => {
        ElMessageBox.alert(respone.data, '提示信息')
    }).catch(reason => {
        ElMessageBox.alert(reason.data, '提示信息')
    })
}

function query(condition:any,result: any, totalQty: any,addData:any) {
    const queryUrl = BASE_URL + "query";
    console.log(queryUrl)
    axios({
        method: "post",
        url: queryUrl,
        headers: {
            'Content-type': 'application/x-www-form-urlencoded'
        },
        data:condition
    }).then(respone => {
        if(respone.data.errorCode=="111111"){
            ElMessageBox.alert(respone.data.errorMsg, '提示信息') 
            return;
        }
        result.value = respone.data.result;
        totalQty.total = result.value.length

        addData.id = respone.data.result[0].id;
        addData.storeId = respone.data.result[0].storeId;
        addData.storeNo = respone.data.result[0].storeNo;
        addData.storeName = respone.data.result[0].storeName;
        addData.pltNo = respone.data.result[0].pltNo;
        addData.sn = respone.data.result[0].sn;
        addData.remark = respone.data.result[0].remark;
    }).catch(reason => {
        console.log(reason);
    })
}

function queryPlt(result: any) {
    const queryUrl = BASE_URL + "queryAllPlt";
    console.log(queryUrl)
    axios({
        method: "post",
        url: queryUrl,
        headers: {
            'Content-type': 'application/x-www-form-urlencoded'
        }
    }).then(respone => {
        if(respone.data.errorCode=="111111"){
            ElMessageBox.alert(respone.data.errorMsg, '提示信息') 
            return;
        }
        result.value = respone.data;
    }).catch(reason => {
        console.log(reason);
    })
}

function deleteStore(data: any) {
    const deleteUrl = BASE_URL + "delete";
    console.log(deleteUrl);
    axios({
        method: "post",
        url: deleteUrl,
        headers: {
            'Content-type': 'application/x-www-form-urlencoded'
        },
        data
    }).then(respone => {
        ElMessageBox.alert(respone.data, '提示信息')
    }).catch(reason => {
        ElMessageBox.alert(reason.data, '提示信息')
    })
}

export {save,deleteStore,query,queryPlt }